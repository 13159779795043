import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ClientTextTestimonial = () => {
  const data = useStaticQuery(
    graphql`
      query {
        video_testi: allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {eq: "CTT"}}}
          ) {
          edges {
            node {
              frontmatter {
                title
                templateKey
                description
                testimonial_type
                date
                featured_image {
                  alt
                  thumbnail {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              id
              html
            }
          }
        }
      }
    `
  )
  const [showAll, setShowAll] = useState(false)
  const [ind, setInd] = useState(0)
  const limit = 242;

  const allTestiData = data.video_testi.edges
  const [list, setList] = useState([...allTestiData.slice(0, 12)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allTestiData.length > 12)

  const handleLoadMore = () => {
    setLoadMore(true)
  }
  const showMore = (event, i) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      setShowAll(true)
      setInd(i)
    }
  }

  const showLess = (event, i) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      setShowAll(false)
      setInd(i)
    }
  }
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allTestiData.length
      const nextResults = isMore
        ? allTestiData.slice(currentLength, currentLength + 12)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < allTestiData.length
    setHasMore(isMore)
  }, [list])

  const contntLength = (content) => {
    const lenCnt = content.substr(3, content.length - 4)
    const final_l = lenCnt.length
    return final_l
  }

  return (
    <>
      <section className="mt-50 lg:mt-1-06 lg:mb-67">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            {list &&
              list.map(({ node: post }, index) => (
                <div key={index} className="testimonial-blc-inner pt-35 pl-6-1 pr-4-3 pb-35 lg:pt-46 lg:pb-46 lg:pl-60 lg:pr-60 xl:pt-51 xl:pb-56-1 xl:pl-82 xl:pr-92 w-full 2sm:w-1/2">
                  <div className="mb-3-3 lg:mb-33">
                    {contntLength(post.html) <= limit ?
                      <div
                        className="testimonial-blc-cnt font-worksans-bold text-rp1 lg:text-h3 text-term-primary"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                      />
                      :
                      (index === ind && showAll ?
                        <>
                          <div
                            aria-hidden="true"
                            onClick={(event) => showLess(event, index)}
                            className="testimonial-blc-cnt font-worksans-bold text-rp1 lg:text-h3 text-term-primary"
                            dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, contntLength(post.html)))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see less</a>` }}
                          />
                        </>
                        :
                        <>
                          <div
                            aria-hidden="true"
                            onClick={(event) => showMore(event, index)}
                            className="testimonial-blc-cnt font-worksans-bold text-rp1 lg:text-h3 text-term-primary"
                            dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, limit))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see more</a>` }}
                          />
                        </>
                      )}
                  </div>
                  <div className="flex flext-wrap items-center">
                    <div className="mr-2-3 lg:mr-5-1">
                       <GatsbyImage image={getImage(post.frontmatter.featured_image.thumbnail)} alt={post.frontmatter.featured_image.alt} className="w-10 h-10 lg:w-60 lg:h-60 rounded-full mx-auto" />
                    </div>
                    <p className="font-worksans-normal text-xs lg:text-sm xl:leading-9 text-term-primary">
                      <span className="font-worksans-bold">{post.frontmatter.title}</span>
                      <br className="xl:hidden"></br> {post.frontmatter.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          {hasMore && (
            <div className="mt-43 mb-50 lg:mb-0 text-center lg:mt-72 xl:mt-92">
                <a
                  href={void (0)}
                  onClick={handleLoadMore}
                  className="py-3 pl-36 pr-35 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                >
                  Load More Testimonials
                </a>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
export default ClientTextTestimonial