import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Video from "../video"

const ClientVideoTestimonial = () => {
  const data = useStaticQuery(
    graphql`
      query {
        video_testi: allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {in: ["CVT","CVTT"]}}}
          ) {
          edges {
            node {
              frontmatter {
                title
                templateKey
                description
                testimonial_type
                date
                featured_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                      )
                    }
                  }
                }
                upload_video
              }
              id
              html
            }
          }
        }
      }
    `
  )

  const allTestiData = data.video_testi.edges

  const [list, setList] = useState([...allTestiData])

  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)
  const handleVideoIsOpen = (event, index) => {
    setOpenVideoIndex(index)
    setIsVideoOpen(true);
  }
  function handleVideoIsClose(isopenVideoState) {
    setIsVideoOpen(isopenVideoState);
  }
  
  return (
    <>
      {list &&
        list.map(({ node: video }, i) => (
          <div key={i}>
            <Video isVideoOpen={isVideoOpen} openVideoIndex={openVideoIndex} onChange={handleVideoIsClose} videoSrcURL={video.frontmatter.upload_video} elementIndex={i} />
          </div>
        ))}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6-6 gap-y-35 md:gap-y-45">
        {list &&
          list.map(({ node: post }, index) => (
            <div key={index}>
              <div className="testimonial-review relative cursor-pointer" onClick={(event) => handleVideoIsOpen(event, index)}>
                <GatsbyImage className="w-auto h-full mx-auto" image={getImage(post.frontmatter.featured_image.image)} alt={post.frontmatter.featured_image.alt || `decodeup clients testimonials`} />
                <span className="testi-video-play-button absolute">
                  <span></span>
                </span>
              </div>
              <div className="pt-3-3 lg:pt-6-1">
                <h3 className="mb-1 lg:mb-0-2 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                  {post.frontmatter.title}
                </h3>
                <h6 className="mb-1-3 font-poppings-normal text-xs lg:leading-25 text-term-primary">
                  {post.frontmatter.description}
                </h6>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
export default ClientVideoTestimonial