import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

//component
import Layout from "../components/layout";
import Seo from "../components/seo";
import ClientVideoTestimonial from "../components/Testimonials/client-video-testimonial";
import ClientTextTestimonial from "../components/Testimonials/client-text-testimonial";
import LiveChat from "../components/Home/livechat";

const Testimonials = ({ data, location }) => {
  const thumbnails = data.thumbnails.edges
  const pageData = data.TestipageData
  const SEOdata = data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.awardpageData.edges[0].node.frontmatter?.Hreflang
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Testimonials"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner section */}

        <section className="mt-60 lg:mt-20 testi-bg bg-background-secondary">
          <div className="container mx-auto">
            <div className="relative testimoniad-bg flex items-center">
              <div className="mx-auto px-5 sm:px-20 md:px-50 xl:px-20 w-full md:w-3/5 xl:w-2/5 text-center">
                <p className="mb-1-1 lg:mb-2 font-poppings-bold text-rh6 lg:text-h6 text-cyan">
                  {pageData.frontmatter.banner_title}
                </p>
                <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-white">
                  {pageData.frontmatter.banner_subtitle}
                </h2>
              </div>
              {thumbnails && thumbnails.map(({ node: post }, i) =>
                <div key={i} className={`textimonial-${(i + 1)} absolute hidden sm:block sm:w-50 sm:h-50 md:w-55 md:h-55 lg:w-60 lg:h-60`}>
                  <GatsbyImage image={getImage(post.frontmatter.featured_image.thumbnail)} alt={`${post.frontmatter.featured_image.alt} | Decodeup Clients Thumbnail`} />
                </div>
              )}
            </div>
          </div>
        </section>

        {/* introduction section */}

        <section className="pt-50 lg:pt-70 xl:pt-1-11">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-47 mx-auto w-full xl:w-2/3 text-center">
              <h3 className="mb-1 lg:mb-0 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                {pageData.frontmatter.title}
              </h3>
              <p className="px-4 sm:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary testi-text">
                {pageData.frontmatter.description}
              </p>
            </div>
            <ClientVideoTestimonial />
          </div>
        </section>

        {/* testimonial text listing */}
        <ClientTextTestimonial />

        {/* schedule free cunsultation chat section */}
        <LiveChat />

      </Layout>
    </>
  );
}

export default Testimonials;


export const pageQuery = graphql`
query {
  TestipageData: markdownRemark(fileAbsolutePath: {regex: "/testimonial.md/"}) {
    frontmatter {
      title
      banner_subtitle
      banner_title
      description
    }
  }
  thumbnails: allMarkdownRemark(
    limit: 7
    sort: { order: ASC, fields: [frontmatter___date] }
    filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {eq: "CVTT"}}}
    ) {
      edges {
        node {
          frontmatter {
            date
            featured_image {
              alt
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
    awardpageData: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex: "/testimonial.md/"} }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
`;